import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGamepad,
  faMobileScreenButton,
  faMusic,
  faAddressCard,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { NavBarLoad } from "./components/animations";
import { BorderDiv } from "./components/elements";
import { MenuIcon } from "./components/MenuIcon";

const pages = [
  { url: "Games", classname: faGamepad },
  // { url: "Videos", classname: faMobileScreenButton },
  // { url: "Music", classname: faMusic },
  { url: "Merch", classname: faShoppingCart },
  { url: "Contact", classname: faAddressCard },
];

const size = {
  mobile: "320px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "2560px",
};

const NavBarDiv = styled.div`
  display: grid;
  grid-template-areas:
    "logo menu pad"
    "border border border";
  grid-template-columns: 1fr 5fr 1fr;
  max-height: fit-content;
  min-height: 5rem;
  animation: ${NavBarLoad} 300ms ease-in;
  z-index: 100;

  @media only screen and (max-width: ${size.tablet}) {
    display: none;
  }
`;

const LogoDiv = styled.div`
  display: grid;
  grid-area: logo;
  height: 100%;
  place-items: left;
`;

const MenuDiv = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  //   place-items: center;
  //   grid-template-areas: "Games Apps Music Contact Booking ";
  svg {
    @media only screen and (max-width: ${size.laptop}) {
      display: none;
    }
  }

  }
`;

interface MenuButtonProps {
  area: string;
  fontSize?: string;
}

const MenuButton = styled.span<MenuButtonProps>`
  display: grid;
  grid-template-areas: "ico text";
  place-items: center;
  cursor: pointer;
  font-size: ${(props) => props.fontSize || "1.5em"};
  gap: 1rem;
  grid-area: ${(props) => props.area};

  @media only screen and (max-width: ${size.tablet}) {
    display: none;
  }
`;

const PadDiv = styled.div`
  display: grid;
  grid-area: "pad";
`;

interface LogoProps {
  height?: string;
}

const Logo = styled.img<LogoProps>`
  height: ${(props) => props.height || "5em"};
  cursor: pointer;
  margin: 10px;
`;

interface ScrollBarDivProps {
  top: string;
}

const ScrollBarDiv = styled.div<ScrollBarDivProps>`
  position: fixed;
  top: ${(props) => props.top};
  transition: top 0.3s;
  background-color: rgb(1, 22, 39);
  height: 5rem;
  z-index: 100;

  @media only screen and (max-width: ${size.tablet}) {
    position: relative;
`;

const ScrollBarDivGrid = styled.div`
  display: grid;
  grid-template-areas:
    "logo menu pad"
    "border border border";
  grid-template-columns: 1fr 5fr 1fr;
  min-height: 5rem;
  width: 100vw;
`;

const VElem = styled.div`
  height: 100%;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.5em;
`;

const NavBarMobile = styled.ul`
  padding-top: 1rem;
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #011627;
  margin: 0;
  gap: 2rem;
  padding: 1em 0;
  transition: max-height 0.5s ease-in;
  overflow: hidden;
  border-bottom: 1px solid #fff;
`;

const ResponsiveAppBar = () => {
  const [isNavShowing, setIsNavShowing] = useState(false);
  const homeRef = useRef<HTMLDivElement>(null);
  const dropdown = useRef<HTMLUListElement>(null);
  const [mobileState, setMobileState] = useState(false);
  const [contentHeight, setContentHeight] = useState("0px");
  const [navbarPos, setNarbarPos] = useState<string>("-150px");
  const navigate = useNavigate();

  useEffect(() => {
    function handleWindowEvents() {
      if (document.body.scrollWidth <= 768) {
        setMobileState(true);
        setNarbarPos("0");
        return;
      }
      if (mobileState) {
        setMobileState(false);
      }

      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        setNarbarPos("0");
      } else {
        setNarbarPos("-150px");
      }
    }
    handleWindowEvents();

    window.onscroll = function () {
      handleWindowEvents();
    };

    window.onresize = function () {
      handleWindowEvents();
    };
    window.onload = function () {
      handleWindowEvents();
    };
  });

  useOnClickOutside(homeRef, () => closeOnClick());

  function closeOnClick() {
    if (isNavShowing) {
      toggleNav();
    }
  }

  const toggleNav = () => {
    if (isNavShowing) {
      setContentHeight("0px");
    } else {
      if (dropdown !== undefined && homeRef !== undefined) {
        let headerPx = homeRef.current?.offsetHeight as number;
        let dropdownPx = dropdown.current?.scrollHeight as number;
        setContentHeight(headerPx + dropdownPx + 50 + "px");
      }
    }
    setIsNavShowing(!isNavShowing);
  };

  const navigateTo = (url: string) => {
    navigate(`/${url}`);
    if (isNavShowing) {
      toggleNav();
    }
  };

  return (
    <>
      <NavBarDiv>
        <LogoDiv>
          <Logo
            src={"./MatatoLogo.png"}
            onClick={(e) => {
              navigateTo("");
            }}
          />
        </LogoDiv>
        <MenuDiv className="menu-div">
          {pages.map((page) => (
            <MenuButton
              className="menu-button"
              area={page.url}
              key={page.url}
              onClick={(e) => {
                navigateTo(page.url);
              }}>
              <FontAwesomeIcon icon={page.classname} /> {page.url}
            </MenuButton>
          ))}
        </MenuDiv>
        <PadDiv></PadDiv>

        <BorderDiv></BorderDiv>
      </NavBarDiv>

      <ScrollBarDiv top={navbarPos} ref={homeRef}>
        <ScrollBarDivGrid>
          <LogoDiv>
            <Logo
              src={"./MatatoLogo.png"}
              height={"3.5rem"}
              onClick={(e) => {
                navigateTo("");
              }}
            />
          </LogoDiv>
          <MenuDiv>
            {pages.map((page) => (
              <MenuButton
                area={page.url}
                fontSize={"1em"}
                key={page.url}
                onClick={(e) => {
                  navigateTo(page.url);
                }}>
                {page.url}
              </MenuButton>
            ))}
          </MenuDiv>
          <BorderDiv></BorderDiv>
          <MenuIcon onClick={toggleNav} checked={isNavShowing} isVisable={mobileState} />
        </ScrollBarDivGrid>
        <NavBarMobile
          ref={dropdown}
          style={{
            visibility: isNavShowing ? "visible" : "hidden",
            maxHeight: contentHeight,
          }}>
          {pages.map((page) => (
            <VElem key={page.url}>
              <FontAwesomeIcon icon={page.classname} />
              <div
                onClick={(e) => {
                  navigateTo(page.url);
                }}>
                {page.url}
              </div>
            </VElem>
          ))}
        </NavBarMobile>
      </ScrollBarDiv>
    </>
  );
};
export default ResponsiveAppBar;

function useOnClickOutside(ref: any, handleOutsideClick: (event: Event) => any) {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handleOutsideClick(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handleOutsideClick]);
}
