import styled from "styled-components";
import { BorderDiv, Content } from "../components/elements";
import instagram from "../assets/instagram.svg";
import tiktok from "../assets/tiktok.svg";
import youtube from "../assets/youtube.svg";

const Title = styled.h1`
  text-align: center;
`;

const SocialMediaLogos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
`;

const SocialLogo = styled.img`
  width: 4rem;
  height: 4rem;
  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;
const ContactImage = styled.img`
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  gap: 2rem;
  justify-self: center;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
  grid-gap: 10px;
  width: 100%;
  max-width: 725px;

  @media (max-width: 768px) {
    max-width: 450px;
  }
  @media (max-width: 480px) {
    max-width: 350px;
  }
`;

export default function Contact() {
  return (
    <Content className="content">
      <Title>Contact me</Title>
      <Container>
        {/* <ContactImage src="https://media-exp1.licdn.com/dms/image/C5603AQEYgZBI1LQl7w/profile-displayphoto-shrink_800_800/0/1633632720528?e=2147483647&v=beta&t=esWqOFW4e69dpGXu3WKXR8E6s1KcWF8XXUZ2_GJC668"></ContactImage> */}
        <p style={{ textAlign: "center" }}>
          Follow me @MatatoGames to keep up to date with the latest news and releases!
        </p>
        <SocialMediaLogos>
          <a href="https://www.instagram.com/matatogames/" target="_blank" rel="noreferrer">
            <SocialLogo src={instagram} />
          </a>
          <a href="https://www.tiktok.com/@matatogames" target="_blank" rel="noreferrer">
            <SocialLogo src={tiktok} />
          </a>
          <a href="https://www.youtube.com/channel/UCaMgrj4whNW1sYK-2-T85GQ" target="_blank" rel="noreferrer">
            <SocialLogo src={youtube} />
          </a>
        </SocialMediaLogos>
        <p style={{ textAlign: "center" }}>Contact: matatogamestudio@gmail.com</p>
      </Container>
    </Content>
  );
}
